form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

input,
textarea,
select {
  border-radius: $border-radius * 2;
  border: 3px solid #878787;
  outline: none;
  padding: $spacing;
  transition: all 0.2s;
  touch-action: manipulation;
  font-family: 'Montserrat', sans-serif;
  background: transparent;
  -webkit-appearance: none;

  &:focus {
    border-color: map_get($colors, "blue");
  }

  &:focus + label {
    transform: translate(8px, 0);
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    z-index: 2;
    background-color: white;
  }
}

textarea {
  resize: vertical;
}

select:not(:checked) {
  position: relative;
  z-index: 1;
  background-color: transparent;
}

label {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #878787;
  transition: all 0.2s;
  touch-action: manipulation;
  transform: translate(16px, 35px);
  max-width: max-content;
  cursor: text;
  position: absolute;
  top: -17px;

  &.not-empty-field {
    transform: translate(8px, 0);
    padding: 8px;
    background-color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    z-index: 10;
  }

  & > span {
    margin: 0 4px;
    color: red;
  }
}

.form-field {
  display: flex;
  flex-flow: column-reverse;
  position: relative;
  width: 100%;
  margin-bottom: $spacing * 1.5;

  &.half-size {
    width: 50%;
  }

  &.third-size {
    width: 33%;
  }

  &.with-margin {
    margin-right: $spacing * 2;
    width: calc(50% - #{$spacing * 2});
  }

  &:last-of-type {
    margin-bottom: $spacing * 2.5;
  }
}