.page-hero {
  position: relative;
  padding: ($spacing * 6) ($spacing * 12);
  display: flex;
  justify-content: center;
  margin-bottom: $spacing * 2;

  & .sidebar__left {
    height: 200%;
  }

  &__text {
    flex-basis: 50%;
    text-align: left;

    h2 {
      font-weight: 400;
      font-size: 3rem;
    }

    @media screen and (max-width: 991px) {
      & a {
        display: block;
        text-align: center;
      }
    }
  }

  &__image {
    flex-basis: 50%;
    position: relative;
    display: flex;
    justify-content: center;

    & img {
      position: absolute;
      top: -50%;
      transform: scale(.7);

      @media screen and (min-width: 1500px) {
        top: -70%;
      }
    }
  }
}

.panels {
  position: relative;
  padding: ($spacing * 6) ($spacing * 12);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__column {
    flex-basis: calc(33% - #{$spacing * 4.5});
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    margin-right: $spacing * 4.5;

    &:last-of-type {
      margin-right: 0;
      margin-top: 420px;
    }

    &:nth-of-type(2) {
      margin-top: 210px;
    }
  }

}

.panel {
  background-color: map_get($colors, "white");
  border-radius: $border-radius * 4;
  box-shadow: 15px 15px 100px rgba(0, 0, 0, 0.1);
  padding: $spacing * 3;
  position: relative;
  margin-bottom: $spacing * 3.5;

  &__image {
    position: relative;
    margin-bottom: $spacing * 2;

    & > .image-bg {
      position: absolute;
      bottom: -24px;
      left: 48px;
    }

    & > img:not(.image-bg) {
      width: 128px;
    }
  }

  &__content {}

  &__title {
    font-size: 1.375rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: $spacing;
  }

  &__text {
    text-transform: uppercase;
    font-weight: 300;
  }
}

.faq {
  position: relative;
  padding: ($spacing * 10) ($spacing * 12);

  &__bg {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}

.questions {
  width: 90%;
  margin: 0 auto;
  margin-top: ($spacing * 10);
  display: flex;
  flex-wrap: wrap;
}

.question {
  margin-bottom: $spacing * 2.5;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__title {
    width: 75%;
    background-color: map_get($colors, "blue");
    border-radius: $border-radius * 4;
    border-bottom-left-radius: 0;
    padding: ($spacing * 1.5) ($spacing * 3.5);
    font-size: 2rem;
    color: #FFF;
    margin-bottom: $spacing;

    &::before {
      content: attr(data-question);
      font-size: 2rem;
      font-weight: 700;
      color: #FFF;
      margin-right: $spacing * 1.5;
    }
  }

  &__answer {
    width: 75%;
    margin-left: 25%;
    background-color: map_get($colors, "green");
    border-radius: $border-radius * 4;
    border-bottom-right-radius: 0;
    padding: ($spacing * 1.5) ($spacing * 3.5);
    font-size: 1.5rem;
    color: #FFF;
  }
}

.contact-page {
  padding-top: 0;

  &__contact-persons {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 1199px) {
      padding: 0 $spacing * 10;
    }

    &::after {
      content: " ";
      background-image: url("../img/contact-founders-bg.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 320px;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
    }

    .person {
      position: relative;

      @media screen and (min-width: 992px) {
        flex-basis: 50%;
      }

      &__image {
        overflow: hidden;
        padding: 16px;
        text-align: left;

        &--right {
          text-align: right;
        }
      }

      &__photo {
        display: none;
        width: 70%;
        position: relative;
        margin: 0 auto 0 0;

        &--right {
          left: unset;
          margin: 0 0 0 auto;
        }

        @media screen and (min-width: 992px) {
          display: block;
        }

        &--mobile {
          display: block;
          width: 100%;
          transform: scale(1.2);
          margin-left: $spacing;

          @media screen and (min-width: 992px) {
            display: none;
          }
        }
      }

      &__info {
        position: absolute;
        top: 130px;
        right: 0;
        width: 50%;

        @media screen and (min-width: 992px) {
          position: relative;
          top: unset;
          right: unset;
          width: 100%;
          padding: 0 0 ($spacing * 2) ($spacing * 6);

          &--right-align {
            text-align: right;
            padding: 0 ($spacing * 6) ($spacing * 2) 0;

            & .social-section__social-links {
              justify-content: flex-end;
            }

            & .social-link {
              justify-content: flex-end;
            }
          }
        }

        @media screen and (min-width: 1200px) {
          padding: 0 0 ($spacing * 4) ($spacing * 8);

          &--right-align {
            padding: 0 ($spacing * 8) ($spacing * 4) 0;
          }
        }

        @media screen and (min-width: 1500px) {
          padding: 0 0 ($spacing * 6) ($spacing * 10);

          &--right-align {
            padding: 0 ($spacing * 10) ($spacing * 6) 0;
          }
        }

        & .social-link {
          font-size: 1rem;
          margin-bottom: $spacing;
        }

        & .social-link__logo {
          width: 40px;
          height: 40px;
          margin-right: $spacing / 2;

          & > img {
            width: 24px;
          }
        }
      }

      &__name {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: $spacing;

        @media screen and (min-width: 992px) {
          font-size: 2rem;
        }

        @media screen and (min-width: 1200px) {
          font-size: 2rem;
        }
      }
    }
  }

  .income__text p::after {
    background-image: url("../img/icons/arrow-right-full-blue.png");
    left: 0;
    width: 680px;
    background-size: 100%;
  }

  & .sidebar {
    height: 200%;
  }

  .social-section {
    padding-bottom: $spacing * 20;
    background-image: url("../img/contact-founders-bg.svg");
    background-position: bottom;
  }
  .social-section__text h6 {
    font-weight: 400;
  }

  & .social-section {
    background-color: #f1f1f1;
  }

  & .social-section__images img {
    right: -410px;

    @media screen and (max-width: 991px) {
      right: 50%;
    }
  }
}

.articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ($spacing * 10) ($spacing * 12);
  position: relative;
  z-index: 1000;

  & .sidebar__right {
    bottom: 0;
    height: 40%;
  }

  &__block {
    margin-bottom: $spacing * 8;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: ($spacing * 1.5) ($spacing * 4);
    grid-template-areas: "article-1 article-2" "article-1 article-3";

    & .article:nth-of-type(1) { grid-area: article-1; }
    & .article:nth-of-type(2) { grid-area: article-2; }
    & .article:nth-of-type(3) { grid-area: article-3; }

    &:last-of-type {
      margin-bottom: $spacing * 4.5;
    }
  }
}

.article {
  background-color: #FFF;
  box-shadow: 15px 15px 100px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius * 4;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &__compact {
    & .article__thumbnail {
      flex: 1 0 35%;

      & img {
        border-radius: 0;
        border-top-left-radius: $border-radius * 4;
        border-bottom-left-radius: $border-radius * 4;
      }
    }

    & .article__content {
      flex: 1 0 65%;
    }
  }

  &__thumbnail {
    flex: 1 1 100%;
    position: relative;

    &::before {
      content: " ";
      display: block;
      width: 100%;
      padding-top: 50%;
    }

    & > .aspect-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: $border-radius * 4;
      border-top-right-radius: $border-radius * 4;
    }
  }

  &__content {
    padding: $spacing * 1.5;
  }

  &__meta {
    font-size: 0.75rem;
    color: #757575;
    margin-bottom: $spacing * 1.5;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: uppercase;
    color: #000;
    margin-bottom: $spacing;
  }

  &__preview {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: $spacing * 1.5;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__social {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > a:first-of-type {
      margin-right: $spacing * 2;
    }

    & .social-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background-color: #FFF;
      border-radius: 100%;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}