@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .page-header {
    padding: ($spacing * 1.5) ($spacing * 4);
  }

  .homepage-hero__aside img:not(.homepage-hero__special-image) {
    right: -96px;
    top: -160px;
  }

  .founder__photo--right {
    left: -87px;
  }

  .e-book__image img {
    top: -70px;
  }

  .homepage-hero__special-image {
    width: 800px;
    right: -96px;
    top: -398px;
  }

  .page-header__background {
    width: 410px;
  }
  .page-navigation__links .link {
    margin-right: $spacing * 2;
  }
  .homepage-hero__aside {
    z-index: -1;
  }

  .homepage-hero,
  .homepage-services,
  .homepage-about-us,
  .homepage-social-proof,
  .homepage-how-it-works,
  .homepage-income,
  .homepage-partners,
  .homepage-happy-customers,
  .homepage-e-book {
    padding: $spacing * 6;
    margin-bottom: $spacing * 4;
  }

  .social-section {
    padding: $spacing * 6;
    overflow: hidden;
  }

  .social-section__images {
    flex-basis: 50%;
  }
  .social-section__images img {
    right: -470px;
  }

  .founder {
    padding: $spacing * 2;
  }

  .slide__row {
    width: calc(100% - 56px);

    &:last-of-type {
      margin-left: 56px;
    }
  }

  .income__text p::after {
    left: -210px;
  }

  .post__thumbnail {
    padding: 0 ($spacing * 8);
  }

  .post__content-wrapper {
    padding: 0 ($spacing * 9);
  }

  .post__tags {
    padding: ($spacing * 3) ($spacing * 8);
    margin-bottom: $spacing * 3;
  }

  .post__comments {
    padding: 0 ($spacing * 8);
  }

  .comment__new::before {
    left: -11.5%;
  }

  .review__author .rating {
    width: 32px;
    height: 32px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .page-header__background {
    width: 360px;
  }

  .page-header {
    padding: ($spacing * 2);
  }

  .page-navigation__links .link {
    margin-right: $spacing * 1.5;
  }

  .homepage-hero,
  .homepage-services,
  .homepage-about-us,
  .homepage-social-proof,
  .homepage-how-it-works,
  .homepage-income,
  .homepage-partners,
  .homepage-happy-customers,
  .homepage-e-book {
    padding: $spacing * 6;
    margin-bottom: $spacing * 2;
  }

  .homepage-services,
  .homepage-about-us,
  .homepage-social-proof,
  .homepage-how-it-works,
  .homepage-income,
  .homepage-partners,
  .homepage-happy-customers {
    overflow: hidden;
  }

  .social-section {
    padding: $spacing * 6;
    overflow: hidden;
  }

  .homepage-hero__special-image {
    width: 800px;
    right: -96px;
    top: -280px;
  }

  .homepage-hero__aside img:not(.homepage-hero__special-image) {
    right: -65px;
  }

  .homepage-hero__text {
    position: relative;
    z-index: 100;
    flex-basis: 100%;
  }

  .service {
    flex-basis: 50%;
    padding: ($spacing * 3) ($spacing * 2);
  }

  .about-us__header {
    width: 45%;
    position: relative;
    z-index: 100;
  }

  .about-us__founders {
    width: 100%;
  }

  .founder__image:not(.founder__image--right) {
    left: -120px;
  }

  .founder__image--right {
    right: -140px;
  }

  .social-proof__header {
    position: relative;
    z-index: 100;
  }

  .social-proof__proofs,
  .scroll-wrapper {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .proof {
    width: calc(50% - 64px);
    margin: 0 0 $spacing 0;
  }

  .how-it-works__text {
    flex-basis: 100%;
    margin: 0 0 ($spacing * 2) 0;
    text-align: center;
  }

  .how-it-works__video {
    flex-basis: 100%;
  }

  .income__text {
    flex-basis: 100%;
    margin: 0 0 ($spacing * 2) 0;
    text-align: center;
  }

  .income__form-field {
    flex-basis: 100%;
  }

  .slide__row {
    width: 100%;
    margin: 0 0 ($spacing * 2) 0;
  }

  .slide__row:last-of-type {
    margin: 0;
  }

  .review {
    padding: $spacing;
    margin-right: $spacing;
    width: 33%;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .review__author .author-info .author-name {
    font-size: 1rem;
  }

  .review__author img {
    width: 40px;
    height: 40px;
    margin-right: $spacing / 2;
  }

  .review__author .rating {
    width: 32px;
    height: 32px;
  }

  .e-book__content {
    padding: $spacing * 3;
  }

  .e-book__text {
    order: 2;
    flex-basis: 100%;
  }

  .e-book__image {
    flex-basis: 100%;
    order: 1;
  }

  .e-book__image img {
    top: -475px;
    right: 0;
    transform: scale(.5);
  }

  .social-section__text {
    flex-basis: 50%;
  }

  .social-section__images {
    flex-basis: 50%;
  }

  .social-section__images img {
    position: absolute;
    right: -415px;
    top: -160px;
    transform: scale(0.7);
  }

  .panels {
    padding: ($spacing * 4) ($spacing * 2);
  }

  .panels__column {
    width: calc(33% - 32px);
    margin-right: $spacing * 2;
  }

  .panel {
    margin-bottom: $spacing * 2;
  }

  .page-hero,
  .faq,
  .articles {
    padding: $spacing * 6;
  }

  .page-hero__text {
    z-index: 100;
  }

  .page-hero__image img {
    top: -20%;
    right: 0;
  }

  .articles__block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .page-content.blog .page-hero__image img {
    right: -80px;
  }

  .article {
    flex: 1 1 100%;
    margin-bottom: $spacing;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .post {
    margin-top: 0;
  }

  .post__thumbnail {
    padding: 0 ($spacing * 10);
  }

  .post__content-wrapper {
    padding: 0 ($spacing * 11);
  }

  .post__content {
    padding: 0 $spacing;
  }

  .post__tags {
    padding: ($spacing * 4) ($spacing * 10);
  }

  .post__comments {
    padding: $spacing ($spacing * 4) 0;
  }

  .comment__new::before {
    left: -7.5%;
  }

  .partners__logos .logos__row img {
    width: calc(20% - 32px);
    height: 72px;
  }
}

@media screen and (max-width: 991px) {

  .page-content {
    padding-top: 0 !important;
  }

  .page-header {
    padding: $spacing;
  }

  .page-header__background {
    width: 300px;
  }

  .page-header.homepage .page-logo__logo {
    width: 170px;
  }

  .page-navigation {
    display: none;
  }

  .page-navigation__mobile {
    display: block;
  }

  .homepage-hero,
  .homepage-services,
  .homepage-about-us,
  .homepage-social-proof,
  .homepage-how-it-works,
  .homepage-income,
  .homepage-partners,
  .homepage-happy-customers,
  .homepage-e-book {
    padding: $spacing ($spacing * 2);
    margin-bottom: $spacing;
    overflow: hidden;
  }

  .homepage-hero {
    flex-wrap: wrap;
    margin-bottom: $spacing;
    overflow: hidden;
    padding: 0;
  }

  .homepage-hero__aside {
    flex-basis: 100%;
    order: 1;

    & img {
      position: relative;
      width: 100%;
      left: unset;
      right: unset;
      top: unset;
      bottom: unset;

      &:last-of-type {
        top: 110px !important;
        right: -35px !important;
      }
    }
  }

  .homepage-hero__text {
    flex-basis: 100%;
    order: 2;
    padding: 0 ($spacing * 2);

    & .btn:first-of-type {
      margin-right: $spacing;
    }
  }

  .hero-text {
    font-size: 2rem;
  }

  .subhero-text {
    font-size: 0.875rem;
  }

  .homepage-services {
    flex-wrap: wrap;
  }

  .service {
    padding: ($spacing * 3) $spacing;
    flex-basis: 100%;
    margin-right: 0;

    &:first-of-type {
      margin-bottom: $spacing * 1.5;
    }
  }

  .about-us__header {
    width: 100%;
  }

  .about-us__description {
    padding-bottom: 0;
  }

  .about-us__founders {
    width: 100%;
    flex-wrap: wrap;
  }

  .founder__image {
    position: relative;
    top: unset;
    transform: scale(.8);

    &:not(.founder__image--right) {
      left: -160px !important;
    }

    &--right {
      right: -160px !important;
    }
  }

  .founder__photo {
    position: relative;
  }

  .behind-text {
    z-index: -1;
  }

  .founder {
    position: absolute;
    right: 0;
    width: 80%;
    margin-right: 0;
    padding: 24px;
    top: 410px;

    &:last-of-type {
      right: unset;
      left: 0;
      top: 1030px;

      & .founder__name,
      & .founder__position {
        text-align: left;
      }
    }
  }

  .social-proof__proofs {
    overflow: hidden;
  }

  .scroll-wrapper {
    width: 100%;
    justify-content: unset;
    padding: $spacing 0;
  }

  .graphs__slider {
    display: flex;
  }

  .proof {
    padding: $spacing * 1.5;
    margin-right: $spacing;
    flex-basis: 280px;
    flex-shrink: 0;
    box-shadow: unset;
    border: 1px solid rgba(#000, 0.1);
  }

  .how-it-works__text {
    flex-basis: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: $spacing * 2;
  }

  .how-it-works__text h5 {
    font-size: 4rem;
  }

  .how-it-works__text p::after {
    right: -110px;
    left: unset;
  }

  .how-it-works__video {
    flex-basis: 100%;
  }

  .income__text {
    width: 100%;
    margin-right: 0;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: $spacing * 2;

    & h6 {
      font-size: 4rem;
      margin-bottom: $spacing;
    }

    & p {
      font-weight: normal;
      font-size: 1.125rem;
    }
  }

  .income__form-field {
    flex-basis: 100%;
  }

  .partners__logos .logos__row {
    flex-wrap: wrap;
    margin-bottom: 0;

    & img {
      flex: 1 0 33%;
      margin: $spacing;
      height: 72px;
    }
  }

  .slide__row {
    width: 100%;
    margin: 0 0 ($spacing * 2) 0;
  }

  .slide__row:last-of-type {
    margin: 0;
  }

  .review {
    padding: $spacing;
    margin-right: $spacing;
    width: 100%;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .customers__reviews {
    padding: ($spacing * 1.5) 0 0;
  }

  .review__author {
    flex-wrap: wrap;
  }

  .review__author .author-info .author-name {
    font-size: 1rem;
  }

  .review__author img {
    width: 40px;
    height: 40px;
    margin-right: $spacing / 2;
    margin-bottom: $spacing / 2;
  }

  .review__author .rating {
    width: 40px;
    height: 40px;
    flex: 0 auto;
    position: absolute;
  }

  .homepage-happy-customers {
    overflow: visible;
  }

  .e-book__content {
    padding: $spacing * 3;
    background-color: transparent;
    box-shadow: none;
  }

  .e-book__text {
    order: 2;
    flex-basis: 100%;
    padding-top: 280px;

    & h6 {
      font-size: 2rem;
      text-align: center;
    }
  }

  .e-book__image {
    flex-basis: 100%;
    order: 1;
  }

  .e-book__image img {
    top: -150px;
    right: 0;
    transform: scale(.6);
  }

  .social-section {
    padding: $spacing;
    overflow: hidden;
  }

  .social-section__text {
    flex-basis: 60%;
  }

  .social-section__text h6 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: $spacing;
  }

  .social-section__images img {
    position: relative;
    top: unset;
    right: 50%;
    width: 200%;
    transform: unset;
  }

  .page-hero {
    padding: $spacing;
    flex-wrap: wrap;
    padding-top: 84px;
  }

  .page-hero__text {
    flex-basis: 100%;
    order: 2;
  }

  .page-hero__text h2 {
    font-size: 2rem;
    text-align: center;
  }

  .page-hero__image {
    flex-basis: 100%;
    order: 1;
    margin-bottom: $spacing * 2;
  }

  .page-hero__image img {
    position: relative;
    top: unset;
    width: 50%;
  }

  .panels {
    padding: $spacing;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .panels__column {
    width: 100%;
    margin: 0;
    flex-basis: 100%;
    margin-top: unset !important;
  }

  .panel {
    padding: $spacing;
    margin-bottom: $spacing;
  }

  .faq,
  .articles {
    padding: $spacing;
  }

  .page-content.blog .page-hero__image img {
    top: unset !important;
  }

  .articles__block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .page-content.blog .page-hero__image img {
    right: -80px;
  }

  .article {
    flex: 1 1 100%;
    margin-bottom: $spacing;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .post {
    margin-top: 0;
    padding-top: 84px;
  }

  .post__title-and-meta {
    flex-wrap: wrap;
    margin-bottom: $spacing;
  }

  .post__title {
    font-size: 2rem;
    flex: 1 0 100%;
    margin-bottom: $spacing / 2;
  }

  .post__meta {
    text-align: left;
  }

  .post__thumbnail {
    padding: 0 $spacing;
    margin-bottom: $spacing;
  }

  .post__content-wrapper {
    padding: 0 $spacing;
  }

  .post__content {
    padding: 0;
    column-count: 1;
    margin-bottom: 0;
  }

  .post__tags::before {
    content: none;
  }

  .post__tags {
    padding: $spacing;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .post__comments {
    padding: $spacing;
  }

  .comment__new::before {
    left: -7.5%;
  }

  .page-content.blog .page-hero__image img {
    right: 0;
  }

  .comments, ul {
    padding: 0;
  }

  .comment {
    padding: $spacing / 2;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .comment__author,
  .comment__content,
  .comment__actions {
    flex: 1 1 100%;
  }

  .post__comments {
    width: 100%;
    padding-bottom: 0;
  }

  .comments ul, ul ul,
  .comments ul ul, ul ul ul{
    margin-left: $spacing / 2;
    padding-left: $spacing / 2;
  }

  .page-footer {
    padding: $spacing !important;
  }

  .page-footer .page-logo__logo {
    width: 140px;
  }

  .page-footer p {
    font-size: 0.75rem !important;
  }

  .questions {
    margin-top: $spacing;
  }

  .question {
    width: 100%;
  }

  .question__title {
    padding: $spacing;
    width: calc(100% - 32px);
    font-size: 1rem;
  }

  .question__answer {
    width: calc(100% - 32px);
    padding: $spacing;
    font-size: 1rem;
    margin-left: $spacing * 2;
  }

  .form-field.third-size {
    width: 100%;
  }

  .form-field.half-size {
    width: 100%;
    margin-right: 0;
  }

  .homepage-income.contact-page {
    padding-top: 84px;
  }

  .income__text h6 {
    font-size: 2rem;
  }

  .contact-page .income__text p {
    font-size: 1.5rem;
  }

  .contact-page .social-section {
    padding-bottom: 0;
  }
  .contact-page__founders {
    padding: $spacing;
    overflow: hidden;
  }

  .founder-contact {
    flex-basis: 100%;
  }

  .contact-page__founders .founder-contact {
    width: 100%;
  }

  .contact-page__founders .founder__image {
    right: unset !important;
    left: -60px !important;
    top: unset !important;
    transform: scale(.8);
    margin-bottom: -40px;
  }

  .contact-page__founders .founder__image.founder__image--right {
    left: unset !important;
    right: -60px !important;
  }

  .contact-page__founders .founder-contact .founder-name {
    font-size: 1.5rem;
  }

  .contact-page__founders::after {
    content: unset;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 2rem !important;
  }

  .social-proof__header {
    margin-bottom: $spacing;
  }

  .page-header.homepage .page-header__background {
    top: -20px;
    left: -20px;
  }

  .page-header.fixed .page-logo__logo {
    width: 170px;
  }

  .page-logo__logo {
    width: 170px;
  }

  .review {
    display: none;
  }

  .slide__row:first-of-type {
    .review:first-of-type {
      display: flex;
    }
  }
}

@media screen and (max-width: 767px) {
  .slide__row {
    flex-wrap: wrap;

    &:first-of-type {
      margin-bottom: 0;
    }
  }
  .review {
    width: 100%;
    margin: 0 0 8px 0;
  }

  .e-book__image img {
    top: -15px;
    transform: scale(1);
  }

  .e-book__content {
    padding: 0;
  }

  .founder__image:not(.founder__image--right) {
    left: -10px !important;
  }

  .founder {
    right: -30px;
    top: 70px;
    width: 230px;
    z-index: -1;
    padding: 32px 16px 32px 32px;

    &__about {
      text-align: right;
    }

    &--right {
      padding: 32px 32px 32px 16px;

      & .founder__about {
        text-align: left;
      }
    }
  }

  .founder__photo {
    left: -100px !important;
    transform: scale(0.8) !important;
  }

  .founder__image--right {
    right: -30px !important;
    top: -80px;
  }

  .founder__image--right .founder__photo {
    left: unset !important;
    margin-top: -200px;
    right: -100px;
  }

  .founder:last-of-type {
    top: 800px;
    left: -30px;
    margin-top: -230px;
  }

  .about-us__founders {
    margin-bottom: -190px;
  }

  .homepage-hero__aside img:last-of-type {
    top: 60px !important;
    right: -20px !important;
  }

  .article__compact .article__thumbnail img {
    border-bottom-left-radius: 0;
    border-top-right-radius: $border-radius * 4;
  }

  .articles__block {
    margin-bottom: $spacing;
  }
}