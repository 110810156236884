@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes slideUp {
  0% { top: 0; }
  100% { top: -10000px; }
}

@keyframes slideDown {
  0% { top: -1000px; }
  100% { top: 0; }
}

@keyframes slideLeft {
  0% { right: -100%; }
  100% { right: 0; }
}

@keyframes slideRight {
  0% { right: 0; }
  100% { right: 100%; }
}

@keyframes move {
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}