.page-header {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  padding: ($spacing * 3) ($spacing * 8);
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100000;
  transition: all .2s cubic-bezier(0.65, 0.05, 0.36, 1);
  background-color: #FFF;

  &.fixed {
    background-color: map_get($colors, "white");

    & .page-header__background {
      animation: slideUp 1.2s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &.homepage {
    background-color: transparent;

    .page-header__background {
      animation: slideDown .2s cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    .page-logo__logo {
      filter: invert(1);
    }
  }
}

body:not(.homepage) {
  .page-header__background {
    display: none;
  }
}

.page-logo {
  position: relative;

  &__text {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
}

.page-navigation {
  &__mobile {
    display: none;

    &.open {
      & .open-mobile-menu {
        & > div:nth-of-type(1) {
          transform: rotate(45deg);
          top: 40%;
        }
        & > div:nth-of-type(2) {
          left: -48px;
        }
        & > div:nth-of-type(3) {
          transform: rotate(-45deg);
          top: -24%;
        }
      }

      & .mobile-menu {
        right: 0;
      }
    }

    & .open-mobile-menu {
      display: flex;
      width: 32px;
      height: 32px;
      background-color: transparent;
      outline: none;
      border: none;
      position: relative;
      flex-wrap: wrap;
      z-index: 10000000;
      align-items: center;
      overflow: hidden;

      & > div {
        width: 100%;
        height: 2px;
        background-color: map_get($colors, "blue");
        border-radius: $border-radius;
        position: relative;
        transition: all .2s ease-in-out;
      }
    }
  }

  &__links {
    & .link {
      display: inline-block;
      margin-right: $spacing * 5;
      color: map_get($colors, "black");
      padding: ($spacing / 4) 0;
      text-transform: uppercase;

      &:hover {
        border-bottom: 2px solid map_get($colors, "green");
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.highlighted {
        color: map_get($colors, "blue");
      }

      &.phone-contact {
        padding: ($spacing / 2) $spacing ($spacing / 2) ($spacing * 2.5);
        background: $default-gradient;
        color: map_get($colors, "white");
        font-weight: 700;
        border-radius: $border-radius;
        position: relative;

        &::after{
          content: " ";
          position: absolute;
          left: 16px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          background-image: url("../img/icons/phone-icon.svg");
          background-size: 22px 22px;
          background-repeat: no-repeat;
          background-position: center 1px;
          width: 22px;
          height: 22px;
        }

        &:hover {
          border-bottom: none;
        }

        & a {
          padding: ($spacing / 2) 0;
        }
      }

      & a {
        padding: inherit;
      }
    }
  }
}

.logo {
  fill: map_get($colors, "black");
}

.mobile-menu {
  width: 80%;
  position: fixed;
  right: -100%;
  top: 0;
  height: 100%;
  background-color: #FFF;
  background-image: url("../img/menu-bg.png");
  background-position: -32px bottom;
  background-repeat: no-repeat;
  border-top-left-radius: $border-radius * 4;
  border-bottom-left-radius: $border-radius * 4;
  box-shadow: -15px 0px 45px rgba(0, 0, 0, 0.15);
  transition: all .2s ease-in-out;
  z-index: 1000000;

  & ul {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: $spacing;

    & .link {
      flex: 1 1 100%;
      text-align: right;
      margin-bottom: $spacing * 1.5;
      padding: $spacing / 4;
      font-size: 1.5rem;
      text-transform: uppercase;
      position: relative;
      outline: none;

      &.highlighted {
        color: map_get($colors, "blue");
      }

      &:hover,
      &:focus {
        &::after {
          content: " ";
          width: 120px;
          height: 2px;
          background-color: map_get($colors, "green");
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      & a {
        padding: inherit;
        outline: none;
      }
    }
  }
}