.sidebar {
  width: 8px;
  height: 100%;
  background-color: map_get($colors, "green");
  position: absolute;
  top: 0;
  z-index: 10;
  margin: auto 0;

  &.half {
    height: 60%;
    top: 20%;
  }

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  &__bottom {
    bottom: 0;
    left: 0;
    height: 8px;
    width: 20%;
    margin: unset;
    top: unset;
  }
}

.behind-text {
  position: absolute;
  left: 0;
  top: 0;

  &.bottom {
    bottom: 0;
    top: unset;
    right: 0;
    text-align: right;

    & p {
      color: rgba(#8C8C8C, 0.05);
    }
  }

  &.high {
    top: -60px;
  }

  &.light {
    opacity: 0.05;
  }

  p {
    color: #F1F1F1;
    font-size: 10rem;
    font-weight: 700;
    margin-left: -#{$spacing * 2};

    &.move-me {
      margin-left: $spacing * 30;
    }
  }
}

.arrow-down {
  position: absolute;
  right: 0;
  top: 500px;
}

.social-section {
  padding: ($spacing * 4) ($spacing * 16);
  background-color: #F7F7F7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__invisible-link {
    width: 50%;
    height: 100%;
    left: 0;
    position: absolute;
    z-index: 10000;

    @media screen and (min-width: 992px) and  (max-width: 1199px) {
      left: -10%;
    }
    @media screen and (min-width: 1200px) and (max-width: 1499px) {
      left: 15%;
    }

    &.right {
      left: unset;
      right: 0;

      @media screen and (min-width: 992px) and  (max-width: 1199px) {
        right: 10%;
      }
      @media screen and (min-width: 1200px) and (max-width: 1499px) {
        right: -15%;
      }
    }
  }

  &__text {
    flex-basis: 30%;

    & h6 {
      font-size: 3rem;
      margin-bottom: $spacing * 3.5;
    }
  }

  &__images {
    flex-basis: 60%;
    position: relative;

    & img {
      position: absolute;
      right: -240px;
      top: -200px;
      transform: scale(.7);
    }
  }

  &__social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & .social-link {
      flex: 1 1 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 500;
      color: map_get($colors, "blue");
      margin-bottom: $spacing * 1.5;

      @media screen and (max-width: 991px) {
        font-size: 1.125rem;
      }

      &__logo {
        width: 72px;
        height: 72px;
        background-color: #FFF;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacing * 2;

        @media screen and (max-width: 991px) {
          width: 56px;
          height: 56px;
        }
      }
    }
  }
}

.scroll-indicator{
  position: relative;
  width: 28px;
  height: 64px;
  margin: 0 auto;
  text-align: center;

  &__text {
    display: block;
    margin-top: 75px;
    margin-left: -30px;
    font-size: .785rem;
    color: #333;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: .25;
    animation: pulse 2s linear alternate infinite;
  }
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #333;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}