.single-article {
  position: relative;

  & .sidebar {
    height: 60%;
    top: 280px;
  }
}

.post {
  margin-top: $spacing * 8;

  &__thumbnail {
    padding: 0 ($spacing * 16);
    margin-bottom: $spacing * 4;

    & img {
      width: 100%;
      border-radius: $border-radius * 4;
    }
  }

  &__content-wrapper {
    padding: 0 ($spacing * 20);
  }

  &__title-and-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing * 2.5;
  }

  &__title {
    flex: 0 1 70%;
    font-weight: 700;
    font-size: 3.5rem;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    text-align: right;

    & p {
      flex: 1 1 100%;
      color: #757575;

      &:first-of-type {
        margin-bottom: $spacing * 0.75;
      }
    }
  }

  &__content {
    padding: 0 ($spacing * 2);
    column-count: 2;
    column-gap: $spacing * 4;
    margin-bottom: $spacing * 7.5;

    & p {
      margin-bottom: $spacing;
      font-size: 1.125rem;
      color: #000;
      text-align: justify;
      line-height: 1.5;
    }

    & blockquote {
      color: map_get($colors, "green");
      position: relative;
      padding: ($spacing * 5) 0 ($spacing * 5) ($spacing * 3);
      text-align: right;
      font-style: italic;
      line-height: 1.5;
      font-size: 1.125rem;

      &::before {
        content: "“";
        font-size: 21rem;
        font-style: normal;
        font-weight: 700;
        color: rgba(#BEBEBE, 0.1);
        position: absolute;
        top: -50%;
        left: 0;
      }
    }
  }

  &__tags {
    background-color: #FAFAFA;
    padding: ($spacing * 6) ($spacing * 20);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing * 7.5;

    &::before {
      content: "#";
      font-weight: 700;
      font-size: 34rem;
      color: #F7F7F7;
      top: -75%;
      left: 5%;
      position: absolute;
    }

    & .tag-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      z-index: 10;

      & p {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: justify;
        flex: 1 1 100%;

        & .tag {
          color: map_get($colors, "blue");
          font-weight: 400;
          font-size: 1.25rem;
          margin-right: $spacing * 2;

          &:last-of-type {
            margin-right: 0;
          }
        }

        &:first-of-type {
          margin-bottom: $spacing * 0.75;
        }
      }
    }
  }

  &__comments {
    padding: 0 ($spacing * 20) 0;

    & h6 {
      font-size: 3rem;
      margin-bottom: $spacing * 3.5;
    }

    & .btn {
      display: block;
      margin: 0 auto;
    }

    & .sidebar {
      bottom: 660px;
      height: 20%;
      top: unset;
    }
  }

}

.comments {
  margin-bottom: $spacing * 4;
}

.comments, ul {
  padding-left: $spacing * 2.5;

  & ul {
    margin-left: $spacing * 2;
    border-left: 2px solid map_get($colors, "green");

    & ul {
      margin-left: $spacing * 2;
      border-left: 2px solid map_get($colors, "green");
    }
  }
}

.comment {
  display: flex;
  flex-flow: row;
  padding: ($spacing * 1.5) ($spacing * 2);
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  border-radius: $border-radius * 4;
  box-shadow: 5px 15px 60px rgba(0, 0, 0, 0.1);
  margin-bottom: $spacing * 1.5;
  max-width: 90%;

  &__author {
    display: flex;
    align-items: center;
    flex-basis: 25%;

    & img {
      width: 42px;
      height: 42px;
      object-fit: cover;
      border-radius: 100%;
      margin-right: $spacing;
    }
  }

  &__author-name {
    font-size: 1.125rem;
    color: map_get($colors, "blue");
    font-weight: 500;
  }

  &__post-date {
    font-size: 0.75rem;
    color: #7D7D7D;
    margin-bottom: $spacing / 2;
  }

  &__location {
    font-size: 0.625rem;
    color: #979797;
  }

  &__content {
    flex-basis: 65%;
  }

  &__actions {
    flex-basis: auto;
  }

  &__reply {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__new {
    position: relative;
    padding: ($spacing * 9) 0;
    margin-top: $spacing * 6;

    & > h6 {
      text-align: center;
    }

    &::before {
      content: " ";
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: -25%;
      background: linear-gradient(180deg, #FBFBFB 0%, #F1F1F1 100%);
      z-index: -1;
    }

    & form {
      display: flex;
      justify-content: space-between;

      & .btn {
        display: inline-block;
        margin: 0;
      }

      & label {
        background: #FBFBFB;
      }
    }
  }
}