@import "_variables";
@import "_animations";

@import "_buttons";
@import "_forms";
@import "_elements";

@import "_header";

@import "pages/_homepage";
@import "pages/_page";
@import "pages/_article";

@import "_mobile";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  color: map_get($colors, "black");
}

/* width */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map_get($colors, "blue");
  border-radius: $border-radius;
}

ul,
ol {
  list-style-type: none;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

.page-content {
  padding-top: 180px;

  &.faq-page {
    overflow: hidden;

    & .sidebar {
      height: 150%;
    }
  }

  &.blog {
    & .page-hero__image img {
      top: -190%;
    }
  }
}

.page-footer {
  background: $default-gradient;
  padding: ($spacing * 4) ($spacing * 8);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-logo__logo {
    filter: invert(1);
  }

  & p {
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFF;
  }
}