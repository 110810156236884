.homepage-hero {
  position: relative;
  padding: ($spacing * 6) ($spacing * 12);
  margin-bottom: $spacing * 4;
  display: flex;

  &__background {
    position: absolute;
    bottom: -90%;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  & .scroll-indicator {
    @media screen and (min-width: 1200px) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__text {
    flex-basis: 50%;
  }

  &__aside {
    flex-basis: 50%;
    position: relative;

    & img:not(.homepage-hero__special-image) {
      position: absolute;
      right: -35px;
    }
  }

  &__special-image {
    position: absolute;
    width: 1011px;
    height: auto;
    right: -192px;
    top: -398px;
    z-index: 10;
  }
}

.homepage-services {
  position: relative;
  padding: ($spacing * 6) ($spacing * 8);
  display: flex;
  justify-content: center;
  margin-bottom: $spacing * 2;
}

.homepage-about-us {
  position: relative;
  padding: ($spacing * 6) ($spacing * 8) ($spacing * 2);
  margin-bottom: $spacing * 4;
  overflow: hidden;
}

.homepage-social-proof {
  position: relative;
  padding: ($spacing * 2) ($spacing * 8);
  margin-bottom: $spacing * 4;
}

.homepage-how-it-works {
  position: relative;
  background: $default-gradient;
  padding: ($spacing * 8) ($spacing * 10);
}

.homepage-income {
  position: relative;
  padding: ($spacing * 8) ($spacing * 10);
}

.homepage-partners {
  position: relative;
  padding: ($spacing * 5) ($spacing * 10);
  background-color: #F6F6F6;
}

.homepage-happy-customers {
  position: relative;
  padding: ($spacing * 8) ($spacing * 16);
}

.homepage-e-book {
  position: relative;
  padding: 0 ($spacing * 12);
  margin-bottom: $spacing * 4;

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: map_get($colors, "green");
    left: 0;
    z-index: -1;
    top: 50%;
  }
}

/* Elements on homepage */
.hero-text {
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.3;
  margin-bottom: $spacing / 2;
}

#hero-rollup {
  transform-style: preserve-3d;
  position: relative;
  height: 41px;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    height: 41px;
  }

  span {
    position: absolute;
    transition: all .2s ease-in-out;
    z-index: 10000;

    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 41px;
      @media screen and (min-width: 992px) {
        top: 93px;
      }
    }
    &:nth-of-type(3) {
      top: 82px;
      @media screen and (min-width: 992px) {
        top: 186px;
      }
    }
  }

}

.subhero-text {
  text-transform: uppercase;
  margin-bottom: $spacing * 2;
  font-size: 1.5rem;

  & span {
    border-bottom: 2px solid map_get($colors, "green");
    padding: ($spacing / 4) 0;
    font-weight: 500;
  }
}

.service {
  padding: ($spacing * 3.5) ($spacing * 12) ($spacing * 3.5) ($spacing * 4);
  background-color: map_get($colors, "white");
  border-radius: $border-radius * 4;
  margin-right: $spacing * 4.5;
  position: relative;
  overflow: hidden;
  flex-basis: 35%;
  flex-grow: 0;
  box-shadow: 15px 15px 100px rgba(#000, 0.1);

  &:last-of-type {
    margin-right: 0;
  }

  &__image {
    height: 94px;
    margin-bottom: $spacing * 1.5;
    position: relative;

    & img {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__title {
    flex: 1 1 100%;
    margin-bottom: $spacing * 1.5;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__after {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}

.about-us {
  display: flex;

  &__header {
    display: flex;
    flex-wrap: wrap;
    width: 35%;
    margin: 0 auto;
    z-index: 1000;
    position: relative;

    & h3 {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      width: 100%;
      margin-bottom: $spacing;
    }
  }

  &__description {
    text-align: justify;
    padding: $spacing 0 ($spacing * 4.5) 0;
    position: relative;
    font-weight: 300;

    &::before {
      content: " ";
      width: 25%;
      height: 2px;
      background-color: map_get($colors, "green");
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__founders {
    width: 55%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
  }
}

.founder {
  background-color: map_get($colors, "white");
  border-radius: $border-radius * 4;
  box-shadow: 10px 25px 100px rgba(#000, 0.1);
  padding: $spacing * 3.5;
  flex-basis: 60%;
  margin-right: $spacing * 1.5;
  position: relative;
  z-index: 10;

  &:last-of-type {
    margin-right: 0;
    text-align: right;
  }

  &__image {
    position: absolute;
    top: -300px;

    & img:first-of-type {
      transform: scale(0.95);
    }

    &:not(.founder__image--right) {
      left: -350px;
    }

    &--right {
      right: -350px;

      @media screen and (min-width: 1199px) and (max-width: 1500px) {
        right: 56px;
      }
    }
  }

  &__photo {
    position: absolute;

    &:not(.founder__photo--right) {
      width: 700px;
      left: -100px;
      top: -110px;
    }

    &--right {
      width: 560px;
      top: -59px;
      right: -67px;
    }
  }

  &__name {
    font-size: 1.5rem;
    color: map_get($colors, "blue");
    margin-bottom: $spacing / 1.5;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__position {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: map_get($colors, "black");
    margin-bottom: $spacing;
  }

  &__about {
    text-align: justify;
    font-size: 0.75rem;
  }
}

.social-proof {
  display: flex;

  &__header {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto $spacing * 2 auto;

    & h4 {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      width: 100%;
      margin-bottom: $spacing;
    }
  }

  &__proofs {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.proof {
  padding: $spacing * 2;
  background-color: map_get($colors, "white");
  border-radius: $border-radius * 4;
  box-shadow: 10px 25px 100px rgba(#000, 0.1);
  margin-right: $spacing * 2.5;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  &__map {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    & .map-location {
      flex: 1 0 20px;
      word-wrap: break-word;
      color: map_get($colors, "green");
      font-weight: 700;
      font-size: .5rem;
      position: absolute;
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      top: 16px;
    }
  }

  &__customer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: $spacing;
    position: relative;
    z-index: 100;

    & img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 100%;
      margin-right: $spacing;
    }

     & .customer-name {
       font-size: 1.125rem;
       font-weight: 700;
       color: map_get($colors, "blue");
     }
  }

  &__content {
    position: relative;
    z-index: 100;

    & blockquote {
      position: relative;
      font-size: 0.875rem;
      color: map_get($colors, "black");
      padding: 0 ($spacing / 2);
      text-align: justify;
      margin-bottom: $spacing * 1.5;

      &::before,
      &::after {
        content: '“';
        position: absolute;
        color: rgba(#000, 0.05);
        font-size: 6rem;
        font-family: 'Montserrat', sans-serif;
      }

      &::before {
        top: -40px;
        left: -16px;
        transform: rotate(-5deg);
      }

      &::after {
        bottom: -40px;
        right: -16px;
        transform: rotate(185deg);
      }
    }
  }
}

.how-it-works {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__text {
    text-align: left;
    color: map_get($colors, "white");
    flex-basis: 30%;
    margin-right: $spacing * 3.5;

    & h5 {
      font-size: 2rem;
      margin-bottom: $spacing * 2;
      font-weight: 700;
    }

    & p {
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 1.5;
      position: relative;

      &::after {
        content: " ";
        background-image: url("../img/icons/half-arrow-right.png");
        background-repeat: no-repeat;
        width: 580px;
        height: 250px;
        position: absolute;
        bottom: -135%;
        left: 0;
      }
    }
  }

  &__video {
    flex-basis: 60%;
    position: relative;

    & .play-btn {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 90px;
      height: 90px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      z-index: 100;
    }

    & video {
      width: 100%;
      outline: none;
    }
  }
}

.income {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  &--horizontal {
    align-items: center;
  }

  &__text {
    flex-basis: calc(50% - #{$spacing * 4});
    margin-right: $spacing * 4;

    & h6 {
      color: map_get($colors, "black");
      font-size: 2rem;
      margin-bottom: $spacing * 2.5;
    }

    & p {
      font-size: 2rem;
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        bottom: -160px;
        left: -120px;
        background-image: url("../img/icons/arrow-right-full.png");
        background-repeat: no-repeat;
        width: 800px;
        height: 400px;
        z-index: -1;
      }
    }
  }

  &__form-field {
    flex-basis: 50%;
  }
}

.partners {
  & h6 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: $spacing * 4;
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .logos__row {
      display: flex;
      flex-wrap: nowrap;
      flex-flow: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: $spacing;

      &:last-of-type {
        margin-bottom: 0;

        & > img {
          height: 56px;
          
          @media screen and (max-width: 1199px) {
            object-fit: contain;
          }
        }
      }

      & img {
        height: 56px;
      }
    }
  }
}

.customers,
.graphs {

  &__header {
    & h6 {
      font-size: 2rem;
      text-align: center;
    }
  }

  &__reviews {
    padding: ($spacing * 3) 0;
    position: relative;

    &::before {
      content: "“";
      color: #BEBEBE;
      opacity: .1;
      position: absolute;
      top: -56px;
      left: -48px;
      font-size: 350px;
      font-weight: 700;
    }
  }

  &__slider {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;

    .slider__bullets,
    .graphs__bullets {
      display: flex;
      flex-flow: row;

      & .bullet {
        cursor: pointer;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin-right: $spacing / 2;
        background-color: map_get($colors, "blue");
        opacity: 0.25;
        transition: all .2s;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          opacity: 0.5;
        }

        &.active {
          opacity: 1;
        }
      }
    }

    .slider__arrows {
      display: flex;
      flex-flow: row;

      .slider__prev,
      .graphs__prev {
        margin-right: $spacing / 2;
        img {
          transform: rotate(180deg);
        }
      }

      .slider__prev,
      .slider__next,
      .graphs__next,
      .graphs__prev {
        width: 54px;
        height: 54px;
        border-radius: 100%;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;

        & img {
          opacity: 0.25;
        }

        &:hover {
          background-color: #FFF;
          box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);

          & img {
            opacity: 1;
          }
        }
      }
    }
  }
}

.slide {
  flex-wrap: wrap;
  display: flex;
  opacity: 0;
  position: absolute;
  z-index: -1;

  &.active {
    animation: fadeIn .8s ease-in;
    opacity: 1;
    position: relative;
    z-index: 1;
  }

  &__row {
    width: calc(100% - 135px);
    display: inherit;

    &:first-of-type {
      margin-bottom: $spacing * 2;
    }

    &:last-of-type {
      margin-left: 135px;
    }
  }
}

.review {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing * 1.5;
  background-color: map_get($colors, "white");
  border-radius: $border-radius * 4;
  box-shadow: 15px 15px 100px rgba(0, 0, 0, 0.1);
  margin-right: $spacing * 2;
  width: calc(33% - 32px);
  align-items: flex-start;

  &__author {
    display: flex;
    flex-flow: row;
    margin-bottom: $spacing * 1.5;
    align-items: center;
    width: 100%;
    position: relative;

    & img {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      margin-right: $spacing;
    }

    & .author-info {
      .author-name {
        font-size: 1.125rem;
        font-weight: 700;
        color: map_get($colors, "blue");
      }

      .author-location {
        font-size: 0.6rem;
        color: #979797;
      }
    }

    & .rating {
      border-radius: $border-radius * 2;
      background: $default-gradient;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      color: map_get($colors, "white");
      position: absolute;
      right: 0;
    }
  }

  &__content {
    & .added-date {
      font-size: 0.65rem;
      color: #7D7D7D;
      margin-bottom: $spacing;
    }

    & .review-text {
      font-weight: 500;

      &::before {
        content: "“";
      }
      &::after {
        content: "”";
      }
    }
  }
}

.e-book {
  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: ($spacing * 3) ($spacing * 6);
    background-color: map_get($colors, "white");
    border-radius: $border-radius * 4;
    box-shadow: 10px 25px 100px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  &__text {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;

    & h6 {
      flex: 1 1 100%;
      font-size: 2rem;
      font-weight: 700;
      margin-bottom:  $spacing * 2;
      line-height: 1.4;
    }
  }

  &__signup {
    display: flex;
    flex-flow: row;
    position: relative;
    flex: 1 1 100%;

    & input[type="email"] {
      width: 100%;
      border: 3px solid rgba(65, 115, 234, 0.25);

      &::placeholder {
        color: rgba(65, 115, 234, 0.25);
      }

      &:focus {
        border: 3px solid rgba(65, 115, 234, 1);
      }
    }

    & .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      & img {
        height: 12px;
      }
    }
  }

  &__image {
    flex-basis: 50%;
    position: relative;

    & img {
      width: 100%;
      position: absolute;
      transform: scale(1.25);
      top: -180px;
      right: -130px;
    }
  }
}

.scroll-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
}

.graphs__slider {
  display: none;
}

.mobile-fixed-btn {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  transition: all .2s ease-in-out;
  opacity: 0;
  z-index: -1000000;
  animation: fadeIn .2s;

  &.visible {
    opacity: 1;
    z-index: 100000;
  }

   & > .btn {
     padding-left: $spacing * 2.5;
     &::after {
       content: " ";
       position: absolute;
       left: 12px;
       top: 0;
       bottom: 0;
       margin: auto 0;
       background-image: url("../img/icons/phone-icon-blue.svg");
       background-size: 18px 18px;
       background-repeat: no-repeat;
       background-position: center 1px;
       width: 20px;
       height: 20px;
     }
   }
}