.btn {
  padding: $spacing ($spacing * 2);
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  margin-right: $spacing / 2;
  position: relative;
  z-index: 100;

  @media screen and (max-width: 991px) {
    padding: ($spacing / 2) $spacing;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__main {
    background: $default-gradient;
    font-weight: 700;
    color: map_get($colors, "white");
  }

  &__secondary {
    box-shadow: inset 0 0 0 2px map_get($colors, "blue");
    color: map_get($colors, "blue");
    background-color: map_get($colors, "white");
  }
}